

import  GantCustom  from "../../../component/gantt/GantCustom"
import GanttChart from "../../../component/gantt/GanttChart"
import Gantt from "./Gantt"

const Timesheet = () => {
  return (
    <div>
      <GantCustom />
    </div>
  )
}

export default Timesheet
