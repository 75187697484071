
const Gantt = () => {
  return (
    <div>
      Gantt
    </div>
  )
}

export default Gantt
